import React from 'react'
import './Header.css'
const Header = () => {
  return (
    <div className="container">
        <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. </span>
    </div>
  )
}

export default Header
